<template>
  <div>
    <v-snackbar
      v-for="(notification, index) in notifications"
      :key="notification.id"
      :value="notification.show"
      @input="removeNotification(notification)"
      :multi-line="true"
      :top="true"
      :color="notification.type"
      :right="true"
      :timeout="notification.timeout"
      transition="v-snack-transition"
      :style="`top: ${index * 80}px`"
    >
      {{ notification.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="removeNotification(notification)"
        >
          X
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { notifications } from "@/stores/common/notifications/getters";
import { REMOVE_NOTIFICATION } from "@/stores/common/notifications/mutations";
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapMutations } = createNamespacedHelpers("notifications");

export default {
  name: "Notifications",
  data() {
    return {};
  },
  computed: {
    ...mapGetters([notifications]),
  },
  methods: {
    ...mapMutations({
      removeNotification: REMOVE_NOTIFICATION,
    }),
  },
};
</script>

<style scoped>
</style>
