import { isObjectMatched } from '@/js/utils'
import { QuestionApiService } from '@/services/api/service.api.question'
import { groupObjArrayByProperty } from '../../js/utils'
import { ACTION_GET_QUESTIONS } from './actions'
import {
  allShops,
  filterShops,
  ncode,
  hasShop,
  questions,
  answers,
  answerSheet,
  groupedQuestions,
} from './getters'
import {
  SET_ANSWERS,
  SET_NCODE,
  SET_QUESTIONS,
  SET_SHOPS,
  SET_STATUS,
  SET_ANSWER_SHEET,
  SET_MULTIPLE_CHOICE_QUESTION,
  SET_ENQUETE,
} from './mutations'

const state = {
  shops: [],
  questions: [],
  answers: [],
  groupedQuestions: {
    multipleChoice: {},
    enquete: {},
  },
  ncode: null,
  status: null,
  answerSheet: {
    answers: {},
    salesname: "",
    personname: "",
  }
}

const getters = {
  [allShops]: state => state.shops,
  [hasShop]: state => state.shops.length > 0,
  // careful: computed property will not cache this getter
  [filterShops]: state => filterObj => state.shops
    .filter(shop => isObjectMatched(shop, filterObj)),
  [questions]: state => state.questions,
  [answers]: state => state.answers,
  [ncode]: state => state.ncode,
  [answerSheet]: state => state.answerSheet,
  [groupedQuestions]: state => state.groupedQuestions,
}

const actions = {
  async [ACTION_GET_QUESTIONS](context) {
    // get from json file
    const { data: {
      shops,
      answers,
      questions,
      ncode,
      status
    } } = await QuestionApiService.getQuestions();
    const answerSheet = {};
    const multipleChoiceQuestions = questions.filter(question => question.enquete == null);
    const groupedByTitle = groupObjArrayByProperty(multipleChoiceQuestions, 'title', (question) => {
      // init answerSheet keys of question id
      // questions can have many correct answers
      answerSheet[question.id] = [];
      // grab all answers belonging to question
      const questionAnswers = answers.filter(answer => answer.question_id === question.id);
      return {
        ...question, ...{ answers: questionAnswers }
      };
    });
    // get enquete if there is no shop
    if (!shops || shops.length === 0) {
      const enquete = questions.filter(question => question.enquete == '1');
      const enqueteByTitle = groupObjArrayByProperty(enquete, 'title', (enquete) => {
        answerSheet[enquete.id] = ""; // enquete can only have 1 correct answer
        // grab enquete answers
        const enqueteAnswers = answers.filter(answer => answer.question_id === enquete.id);
        return {
          ...enquete, ...{ answers: enqueteAnswers }
        };
      });
      context.commit(SET_ENQUETE, enqueteByTitle);
    }
    context.commit(SET_MULTIPLE_CHOICE_QUESTION, groupedByTitle);
    context.commit(SET_ANSWER_SHEET, { answers: answerSheet });
    context.commit(SET_SHOPS, Object.freeze(shops) || []); // freeze data from API because data will not be edited to save performance
    context.commit(SET_ANSWERS, Object.freeze(answers));
    context.commit(SET_QUESTIONS, Object.freeze(questions));
    context.commit(SET_NCODE, ncode);
    context.commit(SET_STATUS, status);
  }
}

const mutations = {
  [SET_SHOPS](state, shops) {
    state.shops = shops;
  },
  [SET_ANSWERS](state, answers) {
    state.answers = answers;
  },
  [SET_QUESTIONS](state, questions) {
    state.questions = questions;
  },
  [SET_NCODE](state, ncode) {
    state.ncode = ncode;
  },
  [SET_STATUS](state, status) {
    state.status = status;
  },
  [SET_ANSWER_SHEET](state, answerSheet) {
    state.answerSheet = {
      ...state.answerSheet,
      ...answerSheet,
    }
  },
  [SET_MULTIPLE_CHOICE_QUESTION](state, questions) {
    state.groupedQuestions.multipleChoice = questions;
  },
  [SET_ENQUETE](state, questions) {
    state.groupedQuestions.enquete = questions;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
