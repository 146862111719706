import { ApiService } from "./service.api"

export const QuestionApiService = {
  getQuestions() {
    return ApiService.customRequest({
      url: '/data/questions.json',
      method: 'get',
      dataType: "jsonp",
      timeout: 7100,
      crossDomain: true,
      responseType: 'json'
    });
  },

  insertSheet(data) {
    return ApiService.customRequest({
      url: '',
      method: 'get',
      dataType: 'jsonp',
      timeout: 7100,
      crossDomain: true,
      responseType: 'json',
      data
    });
  },

  getResult(id) {
    return ApiService.customRequest({
      url: '/data/sheets.json',
      method: 'get',
      dataType: "jsonp",
      timeout: 7100,
      crossDomain: true,
      responseType: 'json',
      data: { id }
    })
  }
}
