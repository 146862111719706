<template>
  <v-container fluid class="main-container">
    <v-row>
      <v-col cols="12">
        <div class="float-left">
          <img src="" alt="logo1" />
        </div>
        <div class="float-right">
          <img src="" alt="logo2" onclick="x" />
        </div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <h1>理解度テスト</h1>
    </v-row>
    <v-row class="mt-4">
      <v-col
        class="alert alert--info font-weight-bold mt-6 mb-3"
        cols="12"
        role="alert"
      >
        <div v-if="!hasShopPass">
          資格継続にはEラーニング受講が必須となります。<br />
          以下の設問に回答後、送信ボタンを押してください。（全<span
            class="learning-question-count"
            >15</span
          >問） <br />
          既定点(全問正解)達成まで繰り返し受講お願いします。
          <br />
          <br />
          受付期間：9/18～10/23まで
          <br />
        </div>
        <div v-else>
          更新手続きは完了しております。
          <br />
          オフコンペ正規取扱店更新のための問題にご回答いただき、ありがとうございます。
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col col="12" id="learning-form1">
        <!-- use v-slot= props here to prevent bugs on IE 11 -->

        <div id="qlist" class="ml-3 mr-3">
          <!-- results -->
          <answer-history :shops="shops" :ncode="ncode"></answer-history>
          <!-- questions -->
          <div v-if="!hasShopPass">
            <validator-observer
              ref="formObs"
              v-slot="props"
              :isValidatedOnLoad="true"
            >
              <question-group></question-group>

              <!-- shop form -->
              <shop-information-form
                :salesname.sync="salesname"
                :personname.sync="personname"
                :ncode="ncode"
              >
              </shop-information-form>
              <!-- error -->
              <div class="alert alert-danger" role="alert" v-if="props.invalid">
                <h4 class="alert-heading mt-5 mb-2">
                  答えを送信するには以下の条件を満たしてください。
                </h4>
                <ul class="errors learning-error ml-3">
                  <!-- show errors for questiosn -->
                  <div
                    v-for="(error, validatorId) in getErrorBagsForQuestions(
                      props.errors
                    )"
                    :key="validatorId"
                  >
                    <li v-if="error[0]">
                      <a :href="`#${validatorId}`" class="alert-link">{{
                        error[0]
                      }}</a>
                    </li>
                  </div>
                  <!-- show errors for salesname and personname -->
                  <li v-if="props.errors.salesname[0]">
                    <a :href="`#learning-salesname`" class="alert-link">{{
                      props.errors["salesname"][0]
                    }}</a>
                  </li>
                  <li v-if="props.errors.personname[0]">
                    <a :href="`#learning-salesname`" class="alert-link">{{
                      props.errors["personname"][0]
                    }}</a>
                  </li>
                </ul>
              </div>
              <!-- submit btn -->
              <v-btn
                :disabled="props.invalid"
                color="primary"
                width="100%"
                height=""
                class="mt-2 mb-2 submit-btn"
                @click="props.passes(insertSheet)"
              >
                答えを送信
              </v-btn>
              <br />
            </validator-observer>
          </div>
        </div>
      </v-col>
    </v-row>
    <router-view />
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { omit } from "@/js/utils";
import { ACTION_GET_QUESTIONS } from "@/stores/questions/actions";
import { SET_ANSWER_SHEET } from "@/stores/questions/mutations";
import {
  allShops,
  ncode,
  answerSheet,
  filterShops,
} from "@/stores/questions/getters";
import { QuestionApiService } from "@/services/api/service.api.question";
import AnswerHistory from "@/components/AnswerHistory";
import ShopInformationForm from "@/components/forms/ShopInformationForm";
import QuestionGroup from "../components/QuestionGroup.vue";
import ValidatorObserver from "../components/validation/ValidatorObserver.vue";
const {
  mapGetters: questionGetters,
  mapActions: questionActions,
  mapMutations: questionMutations,
} = createNamespacedHelpers("questions");

export default {
  name: "Home",
  components: {
    AnswerHistory,
    ShopInformationForm,
    QuestionGroup,
    ValidatorObserver,
  },
  data() {
    return {};
  },
  computed: {
    ...questionGetters({
      shops: allShops,
      ncode: ncode,
      answerSheet: answerSheet,
      filterShops: filterShops,
    }),
    hasShopPass() {
      return this.filterShops({ pass: "1" }).length > 0;
    },
    salesname: {
      get() {
        return this.answerSheet.salesname;
      },

      set(val) {
        this.setAnswerSheet({ salesname: val });
      },
    },
    personname: {
      get() {
        return this.answerSheet.personname;
      },

      set(val) {
        this.setAnswerSheet({ personname: val });
      },
    },
  },
  created() {
    this.getQuestions().then(() => {
      this.$refs?.formObs?.$refs?.obs.validate();
    });
  },
  mounted() {},
  methods: {
    ...questionActions({
      getQuestions: ACTION_GET_QUESTIONS,
    }),
    ...questionMutations({
      setAnswerSheet: SET_ANSWER_SHEET,
    }),
    getErrorBagsForQuestions(errorBag) {
      return omit(errorBag, "salesname", "personname");
    },
    insertSheet() {
      const data = {
        ...this.answerSheet.answers,
        salesname: this.answerSheet.salesname,
        personname: this.answerSheet.personname,
      };
      QuestionApiService.insertSheet(data);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/home";
</style>
