import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from '@/stores'
import { API_ENDPOINT } from '@/config'
import {
  ACTION_FINISH_LOADING,
  ACTION_SET_LOADING
} from '@/stores/common/loading/actions'

export const ApiService = {
  init() {
    Vue.use(VueAxios, axios)
    // intercept every request
    Vue.axios.interceptors.request.use(
      (request) => {
        if (request.setLoading) {
          store.dispatch(`loading/${ACTION_SET_LOADING}`)
        }
        return request
      },
      (error) => {
        const { response } = error;
        if (response.config.setLoading) {
          store.dispatch(`loading/${ACTION_FINISH_LOADING}`)
        }
        // Do something with request error
        return Promise.reject(response)
      }
    )
    // intercept every response
    Vue.axios.interceptors.response.use(
      (response) => {
        const { config } = response;
        if (config.setLoading) {
          store.dispatch(`loading/${ACTION_FINISH_LOADING}`)
        }
        return response
      },
      (error) => {
        const { response, config } = error;
        if (config.setLoading) {
          store.dispatch(`loading/${ACTION_FINISH_LOADING}`)
        }
        return Promise.reject(response)
      })
    // set default headers
    this.setDefaultHeaders();
  },
  setDefaultHeaders() {
    Vue.axios.defaults.baseURL = API_ENDPOINT
    Vue.axios.defaults.withCredentials = false
    Vue.axios.defaults.headers.common[
      'Content-Type'
    ] = 'application/json'
    Vue.axios.defaults.headers.common[
      'Accept'
    ] = 'application/json'
    Vue.axios.defaults.setLoading = true;
    // Vue.axios.defaults.headers.common.Authorization = `Bearer ${ACCESS _TOKEN}`
  },
  setResponseType(type) {
    Vue.axios.defaults.responseType = type
  },
  get(resource, config = {}) {
    return Vue.axios.get(resource, config)
  },
  post(resource, params, config = {}) {
    return Vue.axios.post(`${resource}`, params, config)
  },
  update(resource, params, config = {}) {
    return Vue.axios.put(resource, params, config)
  },
  delete(resource, config = {}) {
    return Vue.axios.delete(resource, config)
  },
  customRequest(config) {
    return Vue.axios(config)
  },
}
