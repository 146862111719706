import { groupObjArrayByProperty } from '@/js/utils'
import { QuestionApiService } from '@/services/api/service.api.question'
import { ACTION_GET_RESULTS } from './actions'
import {
  questions,
  sheets,
  shop,
  result,
} from './getters'
import {
  SET_QUESTIONS,
  SET_SHOP,
  SET_SHEETS,
  SET_RESULT,
} from './mutations'

const state = {
  shop: {},
  sheets: [],
  questions: [],
  result: {},
}

const getters = {
  [shop]: state => state.shop,
  [sheets]: state => state.sheets,
  [questions]: state => state.questions,
  [result]: state => state.result,
}

const actions = {
  async [ACTION_GET_RESULTS](context, shopId) {
    // get from json file
    const { data: {
      shop,
      questions,
      sheets,
    } } = await QuestionApiService.getResult(shopId);
    const multipleChoiceQuestions = questions.filter(question => question.enquete == null);
    const questionCount = multipleChoiceQuestions.length;
    const correctCount = shop.ng ? questionCount - parseInt(shop.ng, 10) : questionCount;
    const groupedByTitle = groupObjArrayByProperty(multipleChoiceQuestions, 'title', (question) => {
      // grab all answers belonging to question
      const questionAnswers = sheets.filter(answer => answer.question_id === question.id);
      return {
        ...question, ...{ answers: questionAnswers }
      };
    });
    const result = {
      questions: groupedByTitle,
      correctCount,
      questionCount,
    }
    console.log(result);
    // freeze data from API because data will not be edited to save performance
    context.commit(SET_SHOP, Object.freeze(shop));
    context.commit(SET_SHEETS, Object.freeze(sheets));
    context.commit(SET_QUESTIONS, Object.freeze(questions));
    context.commit(SET_RESULT, result);
  }
}

const mutations = {
  [SET_SHOP](state, shop) {
    state.shop = shop;
  },
  [SET_QUESTIONS](state, questions) {
    state.questions = questions;
  },
  [SET_SHEETS](state, sheets) {
    state.sheets = sheets;
  },
  [SET_RESULT](state, result) {
    state.result = result;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
