import {
  ACTION_SET_LOADING,
  ACTION_FINISH_LOADING,
  ACTION_CLEAR_LOADING,
} from './actions'

import {
  SET_LOADING,
  FINISH_LOADING,
  CLEAR_LOADING,
} from './mutations'

const state = {
  loading: 0,
}

const getters = {
  isLoading(state) {
    return state.loading > 0
  },
}

const actions = {
  [ACTION_SET_LOADING](context) {
    context.commit(SET_LOADING)
  },
  [ACTION_FINISH_LOADING](context) {
    context.commit(FINISH_LOADING)
  },
  [ACTION_CLEAR_LOADING](context) {
    context.commit(CLEAR_LOADING)
  }
}

const mutations = {
  [SET_LOADING](state) {
    state.loading++
  },
  [FINISH_LOADING](state) {
    if (state.loading > 0) {
      state.loading--
    }
  },
  [CLEAR_LOADING](state) {
    state.loading = 0
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
