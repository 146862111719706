<template>
  <div id="question-container">
    <div
      class="question-group mt-6"
      :key="title"
      v-for="(questions, title) in multipleChoiceQuestions"
    >
      <h3 class="question--outer-header">
        <img src="/images/h31.gif" alt="" />
        {{ `${title} 'について 全${questions.length}問` }}
      </h3>
      <hr style="display: block" />
      <question
        v-for="question in questions"
        :key="question.id"
        :question="question"
        :answers="question.answers"
        :value="answerChoices[question.id]"
        @input="updateAnswerChoice($event, question.id)"
      ></question>
    </div>
    <div v-if="enquetes">
      <div
        class="question-group mt-6"
        :key="title"
        v-for="(questions, title) in enquetes"
      >
        <h3 class="question--outer-header">
          <img src="/images/h31.gif" alt="" />
          {{ title }}
        </h3>
        <enquete
          v-for="question in questions"
          :key="question.id"
          :question="question"
          :answers="question.answers"
          :value="answerChoices[question.id]"
          @input="updateAnswerChoice($event, question.id)"
        ></enquete>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import {
  hasShop,
  groupedQuestions,
  answerSheet,
} from "@/stores/questions/getters";
import { SET_ANSWER_SHEET } from "@/stores/questions/mutations";
import Question from "./Question.vue";
import Enquete from "./Enquete.vue";
const {
  mapGetters: questionGetters,
  mapMutations: questionMutations,
} = createNamespacedHelpers("questions");
export default {
  components: { Question, Enquete },
  name: "QuestionGroup",
  props: {},
  computed: {
    ...questionGetters({
      hasShop: hasShop,
      groupedQuestions: groupedQuestions,
      answerSheet: answerSheet,
    }),
    multipleChoiceQuestions() {
      return this.groupedQuestions.multipleChoice;
    },
    enquetes() {
      return this.groupedQuestions.enquete;
    },
    answerChoices() {
      return this.answerSheet.answers;
    },
  },
  methods: {
    ...questionMutations({
      setAnswerChoices: SET_ANSWER_SHEET,
    }),
    updateAnswerChoice(val, questionId) {
      this.setAnswerChoices({
        answers: {
          ...this.answerChoices,
          [questionId]: val,
        },
      });
    },
  },
};
</script>
