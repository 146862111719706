import Vue from 'vue'
import Vuex from 'vuex'
import loading from './common/loading';
import notifications from './common/notifications';
import questions from './questions';
import result from './result';

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const store = new Vuex.Store({
  modules: {
    loading,
    notifications,
    questions,
    result,
  },
  strict: debug,
})

export default store
