export const omit = (obj, ...props) => {
  return props.reduce((accumulate, prop) => {
    /* eslint-disable no-unused-vars */
    const { [prop]: omit, ...rest } = accumulate;
    return rest;
  }, obj)
}

export const isNotNullOrNumber = val => {
  return Boolean(val) || val === 0;
}

export const isFunction = v => typeof (v) === 'function'

export const isString = v => typeof (v) === 'string'

export const deepEqual = (x, y) => {
  const keys = Object.keys;
  const typeX = typeof x;
  const typeY = typeof y;
  return x && y && typeX === 'object' && typeX === typeY ?
    (
      keys(x).length === keys(y).length &&
      keys(x).every(key => deepEqual(x[key], y[key]))
    ) :
    (x === y);
}

export const arrayLastEle = arr => {
  return Array.isArray(arr) ? arr[arr.length - 1] : arr
}

export const round = (value, precision) => {
  const rounded = +(Math.round(+value + "e+" + precision) + "e-" + precision);
  return isNaN(rounded) ? 0 : rounded;
}

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const upperCamelCaseToSnakeCase = (value) => {
  return value
    .replace(/^([A-Z])/, ($1) => $1.toLowerCase())
    .replace(/([A-Z])/g, ($1) => `-${$1.toLowerCase()}`);
};

export const milliseconds = (h = 0, m = 0, s = 0) => (h * 60 * 60 + m * 60 + s) * 1000;

export const formatNumber = ({ number, precision = 0, thousand = ',', decimal = '.' }) => {
  number = number || 0
  precision = !isNaN(precision = Math.abs(precision)) ? precision : 2
  thousand = thousand == null ? '' : thousand
  decimal = decimal || '.'
  const negative = number < 0 ? '-' : ''
  let i = parseInt(number = Math.abs(+number || 0).toFixed(precision), 10) + ''
  let j = i.length
  j = j > 3 ? (j % 3) : 0

  let intPart = (j ? i.substr(0, j) + thousand : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousand);
  let decimalValue = precision ? Math.abs(number - i).toFixed(precision).slice(2) : 0;
  let decimalPart = decimalValue != 0 ? decimal + decimalValue : '';

  return negative + intPart + decimalPart;
}

export const formattedToNumber = (formattedNumb, precision) => {
  const number = +formattedNumb.replace(/[^\d.]/g, '');
  return isNaN(number) ? 0 : parseFloat(number.toFixed(precision));
}

export function debounce(cb, wait, immediate) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) cb.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) cb.apply(context, args);
  };
}

export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0;
}

export function deepFreeze(o) {
  Object.freeze(o);
  if (o === undefined) {
    return o;
  }

  Object.getOwnPropertyNames(o).forEach(function (prop) {
    if (o[prop] !== null
      && (typeof o[prop] === "object" || typeof o[prop] === "function")
      && !Object.isFrozen(o[prop])) {
      deepFreeze(o[prop]);
    }
  });

  return o;
}

export const filterObjectByKeys = (obj, keys) => {
  const newObj = {}
  keys.forEach(function (key) {
    if (obj[key]) newObj[key] = obj[key];
  });
  return newObj;
}

// cb is used to map new obj in loop
export const groupObjArrayByProperty = (objArray, property, cb) => {
  const groupedObj = {};
  objArray.forEach((obj) => {
    const object = { ...obj, ...cb(obj) };
    const propertyVal = object[property];
    groupedObj[propertyVal] = Object.prototype.hasOwnProperty.call(groupedObj, propertyVal) ?
      [...groupedObj[propertyVal], object] :
      [object];
  })
  return groupedObj;
}

export const isObjectMatched = (obj, queryParams) => {
  for (const [key, value] of Object.entries(queryParams)) {
    const passed = Array.isArray(value) ? value.includes(obj[key]) : obj[key] == value;
    if (!passed) return false;
  }
  return true;
}

export const mapValues = (obj, f) => {
  const res = {}
  Object.keys(obj).forEach(key => {
    res[key] = f(obj[key], key)
  })
  return res
}
