  <template>
  <div :id="`div_q${question.id}`">
    <h4 class="question--category">{{ question.category }}</h4>
    <h5 class="question--header">
      {{ `Q${question.id}.${question.description}` }}
    </h5>
    <div class="question--answer-group mt-7">
      <validator
        rules="required"
        :label="`div_q${question.id}`"
        :customMessages="renderCustomMessObj(question)"
        v-slot="props"
      >
        <v-checkbox
          class="mt-n6"
          v-for="answer in answers"
          :key="answer.id"
          :value="answer.id"
          :error="props.hasErrors"
          v-model="answerChoices"
        >
          <template v-slot:label>
            <span class="question--answers">{{ answer.answer }}</span>
          </template>
        </v-checkbox>
      </validator>
    </div>
  </div>
</template>

<script>
import Validator from "./validation/Validator.vue";
export default {
  components: { Validator },
  name: "Question",
  data() {
    return {};
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    answers: {
      type: Array,
      required: true,
    },
    value: {
      required: true,
    },
  },
  computed: {
    answerChoices: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    renderCustomMessObj(question) {
      return {
        required: `Q${question.id}を選択して下さい。`,
      };
    },
  },
};
</script>
