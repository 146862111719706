  <template>
  <div :id="`div_q${question.id}`">
    <h4 class="question--category">{{ question.category }}</h4>
    <h5 class="question--header">
      {{ question.description }}
    </h5>
    <validator
      rules="required"
      :label="`div_q${question.id}`"
      :customMessages="renderCustomMessObj(question)"
      v-slot="props"
    >
      <v-radio-group class="question--answer-group mt-4" v-model="answerChoice">
        <v-radio
          :error="props.hasErrors"
          class="mt-n2"
          v-for="answer in answerSorted"
          :key="answer.id"
          :value="answer.id"
        >
          <template v-slot:label>
            <span class="question--answers">{{ answer.answer }}</span>
          </template>
        </v-radio>
      </v-radio-group>
    </validator>
  </div>
</template>

<script>
import Validator from "./validation/Validator.vue";
export default {
  components: { Validator },
  name: "Enquete",
  props: {
    question: {
      type: Object,
      required: true,
    },
    answers: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
    },
  },
  computed: {
    answerChoice: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    answerSorted() {
      const clone = [...this.answers];
      return clone.sort((a, b) => a.sort < b.sort);
    },
  },
  methods: {
    renderCustomMessObj(question) {
      return {
        required: `${question.category}を選択して下さい。`,
      };
    },
  },
};
</script>
