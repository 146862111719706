<template>
  <div class="shop">
    <h2 id="learning-salesname">販売店情報入力</h2>
    <v-col cols="12" class="shop mt-2">
      <v-row class="pb-2">
        <v-col cols="2" class="shop--label">認証コード</v-col>
        <v-col cols="10" class="shop--value">{{ ncode }}</v-col>
      </v-row>
      <v-row class="pb-2">
        <v-col cols="2" class="shop--label">貴店名</v-col>
        <v-col cols="10" class="shop--value">
          <validator
            ref="salesnameValidator"
            label="salesname"
            :customMessages="salesnameMess"
            rules="required"
            mode="passive"
            v-slot="props"
          >
            <v-text-field
              class="ma-0 pa-0 mb-n5"
              type="text"
              size="50"
              maxlength="25"
              placeholder="お店の名前"
              :error="props.hasErrors"
              :value="salesname"
              @input="updateSalesname"
            >
            </v-text-field>
          </validator>
        </v-col>
      </v-row>
      <v-row class="pb-2">
        <v-col cols="2" class="shop--label">ご担当者名</v-col>
        <v-col cols="10" class="shop--value">
          <validator
            ref="personnameValidator"
            label="personname"
            :customMessages="personnameMess"
            rules="required"
            mode="passive"
            v-slot="props"
          >
            <v-text-field
              class="ma-0 pa-0 mb-n5"
              type="text"
              maxlength="25"
              placeholder="山田太郎"
              :value="personname"
              @input="updatePersonname"
              :error="props.hasErrors"
            >
            </v-text-field>
          </validator>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import Validator from "@/components/validation/Validator";

export default {
  name: "ShopInformationForm",
  components: {
    Validator,
  },
  data() {
    return {
      salesnameMess: { required: "貴店名をご入力ください。" },
      personnameMess: { required: "ご担当者名をご入力ください。" },
    };
  },
  props: {
    ncode: {
      type: String,
    },
    personname: {
      type: String,
      required: true,
    },
    salesname: {
      type: String,
      required: true,
    },
  },
  computed: {},
  methods: {
    async updateSalesname(val) {
      const {
        valid,
      } = await this.$refs.salesnameValidator.$refs.provider.validate(val);
      if (valid) this.$emit("update:salesname", val);
    },

    async updatePersonname(val) {
      const {
        valid,
      } = await this.$refs.personnameValidator.$refs.provider.validate(val);
      if (valid) this.$emit("update:personname", val);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/forms/shop-information-form";
</style>