<template>
  <div class="answer-history" v-if="hasShop">
    <h2 class="mt-6 mb-5" v-if="isAdministrator">
      <router-link to="ymsj/">受講結果（管理者用)</router-link>
    </h2>
    <h4 class="mb-3 mt-2">受講履歴</h4>
    <ul>
      <li class="mb-1" v-for="shop in shops" :key="shop.id">
        <router-link
          :class="historyTextClass(shop)"
          :to="{ name: 'result', params: { id: shop.id } }"
        >
          {{ historyText(shop) }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "AnswerHistory",
  props: {
    shops: {
      type: Array,
      required: true,
    },
    ncode: {
      type: String,
    },
  },
  computed: {
    hasShop() {
      return this.shops.length > 0;
    },
    isAdministrator() {
      return this.ncode === "YMSJ1";
    },
  },
  methods: {
    historyText(shop) {
      return `${shop.tantou} 様 ${shop.created} ${
        shop.pass ? "全問正解" : "未達"
      }`;
    },
    historyTextClass(shop) {
      return shop.pass ? "success--text" : "text-warning";
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/answer-history";
</style>
