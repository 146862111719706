<template>
  <v-app>
    <v-main class="grey lighten-5">
      <!-- fade in effect between routes -->
      <Loading :loading="isLoading" />
      <notification />
      <transition name="fade">
        <router-view />
      </transition>
    </v-main>
  </v-app>
</template>
<script>
import Loading from "@/components/common/Loading";
import Notification from "@/components/common/Notification";
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("loading");

export default {
  name: "App",
  components: {
    Loading,
    Notification,
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/app";
</style>