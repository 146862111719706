import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import store from '@/stores';
import router from '@/routes';
import { ApiService } from '@/services';
import notifyMixin from '@/mixins/notify';
import '@/plugins/vee-validate';
// polyfill for vuetify on IE 11
import 'core-js/stable';
import 'regenerator-runtime/runtime';


Vue.config.productionTip = false

ApiService.init();

// global notification mixin
Vue.mixin(notifyMixin);

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
