import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home';

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            isPublic: true
        }
    },
    {
        path: '/result/:id',
        name: 'result',
        component: () => import('@/views/Result'),
        meta: {
            isPublic: true
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 }
    }
})

export default router
