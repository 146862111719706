import { ADD_NOTIFICATION } from "@/stores/common/notifications/mutations";
import randomString from 'random-string';

export default {
  methods: {
    $notify({ type, text, timeout }) {
      if (!timeout) {
        switch (type) {
          case 'info':
            timeout = 5000;
            break;

          case 'error':
            timeout = 8000;
            break;
        }
      }

      const notification = {
        id: randomString({ length: 6 }).toLowerCase(),
        show: true,
        type,
        text,
        timeout,
      };

      this.$store.commit(
        `notifications/${ADD_NOTIFICATION}`,
        notification
      );
    },
  },
}