import { notifications } from "./getters";
import { 
  ADD_NOTIFICATION, 
  CLEAR_NOTIFICATION, 
  REMOVE_NOTIFICATION 
} from "./mutations";



const state = {
  notifications: []
  // notification object shape
  /*
    id     : String
    type   : Enum // info/error
    text   : String
    show   : Boolean
  */
}

const getters = {
  [notifications]: state => state.notifications
}

const actions = {}

const mutations = {
  [ADD_NOTIFICATION](state, notification) {
    Object.freeze(notification); // save performance because notification object is not edited
    state.notifications.push(notification);
  },

  [REMOVE_NOTIFICATION](state, payload) {
    const { id } = payload;
    state.notifications = state.notifications.filter(
      (notification) => notification.id !== id
    );
  },

  [CLEAR_NOTIFICATION](state) {
    state.notifications = [];
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
